import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue';
import { useAccountStore } from '@/stores/account';
import { includeArray } from '@/libs/system';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: TabsPage,
        meta: { auth: ['Member'] },
        children: [
            {
                path: '',
                redirect: 'home'
            },
            {
                path: 'home',
                component: () => import('@/views/HomePage.vue')
            },
            {
                path: 'liked',
                component: () => import('@/views/LikedPage.vue')
            },
            {
                path: 'reminder',
                component: () => import('@/views/ReminderPage.vue')
            },
            {
                name: 'ReminderDetail',
                path: 'reminder/:id',
                component: () => import('@/views/ReminderDetailPage.vue'),
                props: route => ({ videoId: route.query.videoId, videoTitle: route.query.videoTitle, videoDesc: route.query.videoDesc, videoThumbnail: route.query.videoThumbnail })
            },
            {
                name: 'EditReminder',
                path: 'reminder/:id/edit',
                component: () => import('@/views/EditReminderPage.vue'),
                props: route => ({ videoId: route.query.videoId, videoTitle: route.query.videoTitle, videoDesc: route.query.videoDesc, videoThumbnail: route.query.videoThumbnail, reminderId: route.query.reminderId })
            },
            {
                name: 'Saved',
                path: 'saved',
                component: () => import('@/views/SavedPage.vue')
            },
            {
                name: 'SavedDetail',
                path: 'saved/collection/:id',
                component: () => import('@/views/SavedDetailPage.vue')
            },
            {
                path: 'settings',
                component: () => import('@/views/SettingsPage.vue')
            },
            {
                name: 'AccountDetails',
                path: 'settings/account',
                component: () => import('@/views/AccountDetailsPage.vue')
            }
        ]
    },
    {
        name: 'CreateReminder',
        path: '/create-reminder',
        meta: { auth: ['Member'] },
        component: () => import('@/views/EditReminderPage.vue'),
        props: route => ({ videoId: route.query.videoId, videoTitle: route.query.videoTitle, videoDesc: route.query.videoDesc, videoThumbnail: route.query.videoThumbnail })
    },
    {
        name: 'Search',
        path: '/search',
        meta: { auth: ['Member'] },
        component: () => import('@/views/SearchPage.vue'),
        props: route => ({ query: route.query.q })
    },
    {
        name: 'SearchResults',
        path: '/search-results',
        meta: { auth: ['Member'] },
        component: () => import('@/views/SearchResultPage.vue'),
        props: route => ({ query: route.query.q })
    },
    {
        name: 'MultipleVideos',
        path: '/multiple-videos',
        meta: { auth: ['Member'] },
        component: () => import('@/views/MultipleVideoPage.vue')
    },
    {
        name: 'SharedVideo',
        path: '/shared-video/:id',
        component: () => import('@/views/MultipleVideoPage.vue')
    },
    {
        name: 'ReminderVideo',
        path: '/reminder-video/:id',
        component: () => import('@/views/MultipleVideoPage.vue')
    },
    {
        name: 'login',
        path: '/login',
        component: () => import('@/views/auth/LoginPage.vue')
    },
    {
        name: 'register',
        path: '/register',
        component: () => import('@/views/auth/RegisterPage.vue')
    }
]

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) => {
    // 判断是否需要登录才可以进入
    if (to.matched.some(_ => _.meta.auth)) {
        // 这里依据 token 判断是否登录，可视情况修改
        const authenticated = useAccountStore().getIsAuthenticated;
        if (authenticated) {
            // 如果Vuex讀不到資料，就打API
            let userInfo = useAccountStore().getUser;
            if (!userInfo || Object.keys(userInfo).length === 0) {
                useAccountStore().getUserInfo().then(() => {
                    userInfo = useAccountStore().getUser;
                }).catch(() => {
                    next({
                        name: 'login',
                        query: { redirect: to.fullPath }
                    });
                });
            }
            if (userInfo) {
                const access = userInfo.access;
                if (to.meta.auth === true) {
                    next();
                } else {
                    const isPermission = Array.isArray(to.meta.auth) && to.meta.auth.every(item => typeof item === 'string') ? includeArray(to.meta.auth, access) : false;
                    if (isPermission) {
                        next();
                    } else {
                        next({
                            name: '403'
                        });
                    }
                }
            } else {
                next({
                    name: 'login',
                    query: {
                        redirect: to.fullPath
                    }
                });
            }
        } else {
            // 没有登录的时候跳转到登录界面
            // 携带上登陆成功之后需要跳转的页面完整路径
            next({
                name: 'login',
                query: {
                    redirect: to.fullPath
                }
            });
        }
    } else {
        // 不需要身份校验 直接通过
        next();
    }
});

export default router
