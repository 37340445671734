interface I18nConfig {
    default: string;
    auto: boolean;
}

interface SettingType {
    titleSuffix: string;
    routerMode: 'history' | 'hash';
    routerBase: string;
    showProgressBar: boolean;
    apiBaseURL: string | undefined;
    VUE_APP_PUSHER_KEY: string | undefined;
    VUE_PUSHER_CLUSTER: string;
    modalDuration: number;
    errorModalType: 'Message' | 'Notice';
    cookiesExpires: number;
    i18n: I18nConfig;
    sameRouteForceUpdate: boolean;
    dynamicSiderMenu: boolean;
}

const Setting: SettingType = {
    titleSuffix: 'Qi',
    routerMode: 'history',
    routerBase: '/',
    showProgressBar: true,
    apiBaseURL: import.meta.env.VITE_APP_APIBASEURL,
    VUE_APP_PUSHER_KEY: import.meta.env.VITE_APP_PUSHER_KEY,
    VUE_PUSHER_CLUSTER: 'ap1',
    modalDuration: 3,
    errorModalType: 'Message',
    cookiesExpires: 1,
    i18n: {
        default: 'en-US',
        auto: false
    },
    sameRouteForceUpdate: false,
    dynamicSiderMenu: false
};

export default Setting;
