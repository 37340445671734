<template>
    <ion-page>
        <ion-tabs >
            <ion-router-outlet />
            <ion-tab-bar slot="bottom" class="safe-area-padding">
                <ion-tab-button tab="home" href="/home">
                    <div class="top-border"></div>
                    <ion-icon aria-hidden="true" :icon="qiIcon" />
                    <ion-label>{{ $t('home.title') }}</ion-label>
                </ion-tab-button>

                <ion-tab-button tab="like" href="/liked">
                    <div class="top-border"></div>
                    <ion-icon aria-hidden="true" :icon="loveIcon" />
                    <ion-label>{{ $t('liked.title') }}</ion-label>
                </ion-tab-button>

                <ion-tab-button tab="saved" href="/saved">
                    <div class="top-border"></div>
                    <ion-icon aria-hidden="true" :icon="savedIcon" />
                    <ion-label>{{ $t('saved.title') }}</ion-label>
                </ion-tab-button>

                <ion-tab-button tab="reminder" href="/reminder">
                    <div class="top-border"></div>
                    <ion-icon aria-hidden="true" :icon="addIcon" />
                    <ion-label>{{ $t('reminder.title') }}</ion-label>
                </ion-tab-button>

                <ion-tab-button tab="settings" href="/settings">
                    <div class="top-border"></div>
                    <ion-icon class="icon-settings" :src="settingsIcon"></ion-icon>
                    <ion-label>{{ $t('settings.title') }}</ion-label>
                </ion-tab-button>
            </ion-tab-bar>
        </ion-tabs>
    </ion-page>
</template>

<script setup lang="ts">
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';

const homeIcon = '/resources/tabs/Home.svg';
const addIcon = '/resources/tabs/Add.svg';
const settingsIcon = '/resources/tabs/Settings.svg';
const savedIcon = '/resources/tabs/Saved.svg';
const ffIcon = '/resources/tabs/FlowFlickerIcon.svg';
const loveIcon = '/resources/tabs/Love.svg';
const novaIcon = '/resources/tabs/NovaIcon.svg';
const qiIcon = '/resources/tabs/Qi.svg';
</script>

<style lang="scss" scoped>
ion-tab-bar {
    height: 67px;
    border-top: none;
    background: var(--ion-tab-bar-background, var(--ion-color-step-50, var(--ion-background-color-step-50, #f7f7f7)));
    padding-bottom: 13px;
}

ion-tab-button {
    height: 67px;
    --color-selected: #A7ABB1;
    --color: #A7ABB1;

    flex-direction: column !important;
    justify-content: flex-end !important;
    align-items: center !important;

    ion-icon {
        font-size: 28px;
    }
}

ion-tab-button.tab-selected {
    --color-selected: #1A1CC4;
    /* Color when selected */

    .top-border {
        border-top: 3px solid #1A1CC4;
        /* Color when selected */
        border-radius: 0 0 5px 5px;
        width: 60px;
        position: absolute;
        top: 0;
    }
}

.safe-area-padding {
    padding-bottom: env(safe-area-inset-bottom);
}
</style>
